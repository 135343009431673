import ReactPhoneInput, { PhoneInputProps } from "react-phone-input-2";

import "react-phone-input-2/lib/style.css";

interface CustomPhoneInputProps extends PhoneInputProps {
  hasError: boolean;
}

export function PhoneInput({ hasError, ...props }: CustomPhoneInputProps) {
  return (
    <ReactPhoneInput
      {...props}
      country="br"
      placeholder="Telefone"
      inputClass="placeholder:text-residencial-gray !h-10 !w-full !border-none !rounded-sm !text-base"
      containerClass={`!font-poppins focus-within:ring-2 !rounded-sm ring-residencial-teal ${
        hasError && "ring-2 !ring-red-500"
      }`}
      // Template string adicionado pois o container não suporta dataset
      buttonClass="!bg-transparent !border-none"
    />
  );
}
