import { ComponentProps, InputHTMLAttributes, forwardRef } from "react";

export const Input = forwardRef<HTMLInputElement, ComponentProps<"input">>(
  (props, ref) => {
    return (
      <input
        {...props}
        ref={ref}
        className="h-10 w-full rounded-sm px-4 outline-none ring-residencial-teal placeholder:text-residencial-gray focus:ring-2 data-[error=true]:ring-2 data-[error=true]:ring-red-500"
      />
    );
  },
);
