"use client";

import * as Dialog from "@radix-ui/react-dialog";
import { ReactNode } from "react";

interface PrivacityModalProps {
  children: ReactNode;
}

export default function PrivacityModal({ children }: PrivacityModalProps) {
  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>{children}</Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-[9999] bg-[rgba(0,0,0,0.6)]" />
        <Dialog.Content className="policy fixed left-1/2 top-1/2 z-[9999] flex h-[75vh] w-[95%] max-w-[1024px] -translate-x-1/2 -translate-y-1/2 flex-col rounded-xl bg-white lg:aspect-video">
          <header className="flex h-16 items-center justify-end border-b border-zinc-200 px-8">
            <Dialog.Close asChild>
              <button aria-label="Fechar Política de Privacidade">
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  fill="#000000"
                  viewBox="0 0 256 256"
                >
                  <path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path>
                </svg>
              </button>
            </Dialog.Close>
          </header>
          <div
            id="privacity"
            className="flex-1 overflow-auto px-4 py-8 lg:px-8"
          >
            <div>
              <div>
                <strong className="text-2xl">POLÍTICA DE PRIVACIDADE</strong>
              </div>
              <div>
                <p>
                  Este site é mantido e operado por CONSTRUTORA VOLENDAM LTDA.
                </p>
              </div>
              <div>
                <p>
                  Nós coletamos e utilizamos alguns dados pessoais que pertencem
                  àqueles que utilizam nosso site. Ao fazê-lo, agimos na
                  qualidade de controlador desses dados e estamos sujeitos às
                  disposições da Lei Federal n. 13.709/2018 (Lei Geral de
                  Proteção de Dados Pessoais - LGPD).
                </p>
              </div>
              <div>
                <p>
                  Nós cuidamos da proteção de seus dados pessoais e, por isso,
                  disponibilizamos esta política de privacidade, que contém
                  informações importantes sobre:
                </p>
              </div>
              <div>
                <ul>
                  <li>- Quem deve utilizar nosso site;</li>
                  <li>- Quais dados coletamos e o que fazemos com eles;</li>
                  <li>- Seus direitos em relação aos seus dados pessoais; e</li>
                  <li>- Como entrar em contato conosco.</li>
                </ul>
              </div>
              <div>
                <p className="font-bold">
                  1. Dados que coletamos e motivos da coleta
                </p>
              </div>
              <div>
                <p>
                  Nosso site coleta e utiliza alguns dados pessoais de nossos
                  usuários, de acordo com o disposto nesta seção.
                </p>
              </div>
              <div>
                <p>1. Dados pessoais fornecidos expressamente pelo usuário</p>
              </div>
              <div>
                <p>
                  Nós coletamos os seguintes dados pessoais que nossos usuários
                  nos fornecem expressamente ao utilizar nosso site:
                </p>
              </div>
              <div>
                <div>
                  <p>Formulários de Contato do Site:</p>
                </div>
                <ul>
                  <li>- Nome;</li>
                  <li>- E-mail;</li>
                  <li>- DDD + Telefone;</li>
                </ul>
              </div>
              <div>
                <p>
                  A coleta destes dados ocorre nos seguintes momentos:&nbsp;
                </p>
              </div>
              <div>
                <ul>
                  <li>
                    - Solicitação de contato através do formulário de "Fale
                    Conosco";
                  </li>
                  <li>
                    - Solicitação de contato através do formulário "Contato por
                    E-mail";
                  </li>
                  <li>
                    - Solicitação de contato através do formulário "Ofereça seu
                    Terreno";
                  </li>
                  <li>
                    - Solicitação de contato através do formulário de
                    "WhatsApp";
                  </li>
                  <li>
                    - Solicitação de contato através do formulário "Ofereça seu
                    Terreno";
                  </li>
                  <li>
                    - Solicitação de contato através do formulário “Seja nosso
                    Fornecedor";
                  </li>
                  <li>
                    - Solicitação de contato através do formulário de "Trabalhe
                    Conosco";
                  </li>
                </ul>
              </div>
              <div>
                <p>
                  Os dados fornecidos por nossos usuários são coletados com as
                  seguintes finalidades:
                </p>
              </div>
              <div>
                <ul>
                  <li>
                    - Para que o usuário possa receber mais informações sobre um
                    ou mais imóveis específicos e também receber novas ofertas
                    de novas unidades de imóveis.
                  </li>
                  <li>
                    - Para o esclarecimento de alguma dúvida, fazer um elogio ou
                    reclamação.
                  </li>
                </ul>
              </div>
              <div>
                <p>2. Dados pessoais obtidos de outras formas</p>
              </div>
              <div>
                <p>
                  Nós coletamos os seguintes dados pessoais de nossos usuários:
                </p>
              </div>
              <div>
                <ul>
                  <li>- Endereço IP</li>
                  <li>- Dados de Geolocalização</li>
                  <li>- Histórico de navegação e fluxo de acesso ao site.</li>
                </ul>
              </div>
              <div>
                <p>A coleta destes dados ocorre nos seguintes momentos:</p>
              </div>
              <div>
                <ul>
                  <li>- Ao acessar o site e aceitar os Cookies;</li>
                  <li>- Ao navegar nas páginas do site;</li>
                  <li>- Ao fazer contato em "Mais informações";</li>
                </ul>
              </div>
              <div>
                <p>Estes dados são coletados com as seguintes finalidades:</p>
              </div>
              <div>
                <ul>
                  <li>- Personalizar a experiência do usuário;</li>
                  <li>- Parametrizar opções de ofertas de imóveis;</li>
                  <li>- Mapear o uso do site;</li>
                  <li>- Analisar a aceitação do conteúdo ofertado;</li>
                </ul>
              </div>
              <div>
                <p>3. Dados sensíveis</p>
              </div>
              <div>
                <p>
                  <strong>Não</strong> serão coletados dados sensíveis de nossos
                  usuários, assim entendidos aqueles definidos nos arts. 11 e
                  seguintes da Lei de Proteção de Dados Pessoais. Assim,{" "}
                  <strong>não</strong> haverá coleta de dados sobre origem
                  racial ou étnica, convicção religiosa, opinião política,
                  filiação a sindicato ou a organização de caráter religioso,
                  filosófico ou político, dado referente à saúde ou à vida
                  sexual, dado genético ou biométrico, quando vinculado a uma
                  pessoa natural.
                </p>
              </div>
              <div>
                <p>4. Cookies</p>
              </div>
              <div>
                <p>
                  Cookies são pequenos arquivos de texto baixados
                  automaticamente em seu dispositivo quando você acessa e navega
                  por um site. Eles servem, basicamente, para que seja possível
                  identificar dispositivos, atividades e preferências de
                  usuários.
                </p>
              </div>
              <div>
                <p>
                  Os cookies não permitem que qualquer arquivo ou informação
                  sejam extraídos do disco rígido do usuário, não sendo
                  possível, ainda, que, por meio deles, se tenha acesso a
                  informações pessoais que não tenham partido do usuário ou da
                  forma como utiliza os recursos do site.
                </p>
              </div>
              <div>
                <ul>
                  <li>
                    <i>a. Cookies do site</i>
                  </li>
                </ul>
              </div>
              <div>
                <p>
                  Os cookies do site são aqueles enviados ao computador ou
                  dispositivo do usuário e administrador exclusivamente pelo
                  site.
                </p>
              </div>
              <div>
                <p>
                  As informações coletadas por meio destes cookies são
                  utilizadas para melhorar e personalizar a experiência do
                  usuário, sendo que alguns cookies podem, por exemplo, ser
                  utilizados para lembrar as preferências e escolhas do usuário,
                  bem como para o oferecimento de conteúdo personalizado.
                </p>
              </div>
              <div>
                <ul>
                  <li>
                    <i>b. Cookies de terceiros</i>
                  </li>
                </ul>
              </div>
              <div>
                <p>
                  Alguns de nossos parceiros podem configurar cookies nos
                  dispositivos dos usuários que acessam nosso site.
                </p>
              </div>
              <div>
                <p>
                  Estes cookies, em geral, visam possibilitar que nossos
                  parceiros possam oferecer seu conteúdo e seus serviços ao
                  usuário que acessa nosso site de forma personalizada, por meio
                  da obtenção de dados de navegação extraídos a partir de sua
                  interação com o site.
                </p>
              </div>
              <div>
                <p>
                  O usuário poderá obter mais informações sobre os cookies de
                  terceiro e sobre a forma como os dados obtidos a partir dele
                  são tratados, além de ter acesso à descrição dos cookies
                  utilizados e de suas características, acessando o seguinte
                  link:
                </p>
              </div>
              <div>
                <ul>
                  <li>
                    - Google Ads e Analytics:
                    https://static.googleusercontent.com/media/www.google.com/pt-BR//intl/pt-BR/policies/privacy/google_privacy_policy_pt-BR.pdf
                    &nbsp;
                  </li>
                  <li>- Facebook: https://www.facebook.com/policy.php</li>
                  <li>
                    - Instagram:
                    https://www.instagram.com/terms/accept/?hl=pt-br
                    https://www.facebook.com/help/instagram/519522125107875
                  </li>
                </ul>
              </div>
              <div>
                <p>
                  As entidades encarregadas da coleta dos cookies poderão ceder
                  as informações obtidas a terceiros.
                </p>
              </div>
              <div>
                <ul>
                  <li>
                    <i>c. Gestão de cookies</i>
                  </li>
                </ul>
              </div>
              <div>
                <p>
                  O usuário poderá se opor à utilização de cookies pelo site,
                  bastando que os desative no momento em que começa a utilizar o
                  serviço, seguindo as seguintes instruções:
                </p>
              </div>
              <div>
                <p>
                  Assim que entrar no site, o usuário terá a opção de "Aceitar"
                  ou "Não Aceitar" a utilização de cookies, bastando que
                  selecione a opção correspondente na caixa de diálogo (pop up)
                  carregada automaticamente assim que nossa página é acessada.
                </p>
              </div>
              <div>
                <p>
                  A desativação de todos os cookies, no entanto, não será
                  possível, uma vez que alguns deles são essenciais para que o
                  site funcione corretamente.
                </p>
              </div>
              <div>
                <p>
                  Além disso, a desativação dos cookies que podem ser
                  desabilitados poderá prejudicar a experiência do usuário, uma
                  vez que informações utilizadas para personalizá-la deixarão de
                  ser utilizadas.
                </p>
              </div>
              <div>
                <p>5. Coleta de dados não previstos expressamente</p>
              </div>
              <div>
                <p>
                  Eventualmente, outros tipos de dados não previstos
                  expressamente nesta Política de Privacidade poderão ser
                  coletados, desde que sejam fornecidos com o consentimento do
                  usuário, ou, ainda, que a coleta seja permitida com fundamento
                  em outra base legal prevista em lei.
                </p>
              </div>
              <div>
                <p>
                  Em qualquer caso, a coleta de dados e as atividades de
                  tratamento dela decorrentes serão informadas aos usuários do
                  site.
                </p>
              </div>
              <div>
                <p className="font-bold">
                  2. Compartilhamento de dados pessoais com terceiros
                </p>
              </div>
              <div>
                <p>
                  Nós não compartilhamos seus dados pessoais com terceiros.
                  Apesar disso, é possível que o façamos para cumprir alguma
                  determinação legal ou regulatória, ou, ainda, para cumprir
                  alguma ordem expedida por autoridade pública.
                </p>
              </div>
              <div>
                <p className="font-bold">
                  3. Por quanto tempo seus dados pessoais serão armazenados
                </p>
              </div>
              <div>
                <p>
                  Os dados pessoais que coletamos serão armazenados e utilizados
                  pelos seguintes períodos de tempo:
                </p>
              </div>
              <div>
                <ul>
                  <li>
                    - Os dados coletados em formulários, permanecerão
                    armazenados sob nossos cuidados e em segurança até o momento
                    que o usuário solicitar ou o mesmo fazer a remoção, pois
                    entenderemos que, enquanto não solicitar a sua remoção, o
                    mesmo ainda terá o interesse de receber mais informações
                    sobre serviços e produtos, para os quais ele solicitou e
                    outros que julgamos como fazer parte de seu interesse.
                  </li>
                </ul>
              </div>
              <div>
                <p>
                  Os períodos informados não são superiores ao estritamente
                  necessário, atendendo às finalidades e às justificativas
                  legais para o tratamento dos dados.
                </p>
              </div>
              <div>
                <p>
                  Vale dizer que, se houver alguma justificativa legal ou
                  regulatória, os dados poderão continuar armazenados ainda que
                  a finalidade para a qual foram coletados ou tenham sido
                  tratados tenha se exaurido.
                </p>
              </div>
              <div>
                <p>
                  Uma vez finalizado o tratamento, observadas as disposições
                  desta seção, os dados são apagados ou anonimizados.
                </p>
              </div>
              <div>
                <p className="font-bold">
                  4. Bases legais para o tratamento de dados pessoais
                </p>
              </div>
              <div>
                <p>
                  Uma base legal para o tratamento de dados pessoais nada mais é
                  que um fundamento jurídico, previsto em lei, que o justifica.
                  Assim, cada operação de tratamento de dados pessoais precisa
                  ter uma base legal a ela correspondente.
                </p>
              </div>
              <div>
                <p>
                  Nós tratamos os dados pessoais de nossos usuários nas
                  seguintes hipóteses:
                </p>
              </div>
              <div>
                <ul>
                  <li>
                    - mediante o consentimento do titular dos dados pessoais
                  </li>
                  <li>
                    - quando necessário para atender aos interesses legítimos do
                    controlador ou de terceiro
                  </li>
                </ul>
              </div>
              <div>
                <p>1. Consentimento</p>
              </div>
              <div>
                <p>
                  Determinadas operações de tratamento de dados pessoais
                  realizadas em nosso site dependerão da prévia concordância do
                  usuário, que deverá manifestá-la de forma livre, informada e
                  inequívoca.
                </p>
              </div>
              <div>
                <p>
                  O usuário poderá revogar seu consentimento a qualquer momento,
                  sendo que, não havendo hipótese legal que permita ou que
                  demande o armazenamento dos dados, os dados fornecidos
                  mediante consentimento serão excluídos.
                </p>
              </div>
              <div>
                <p>
                  Além disso, se desejar, o usuário poderá não concordar com
                  alguma operação de tratamento de dados pessoais baseada no
                  consentimento. Nestes casos, porém, é possível que não possa
                  utilizar alguma funcionalidade do site que dependa daquela
                  operação. As consequências da falta de consentimento para uma
                  atividade específica são informadas previamente ao tratamento.
                </p>
              </div>
              <div>
                <p>2. Legítimo interesse</p>
              </div>
              <div>
                <p>
                  Para determinadas operações de tratamento de dados pessoais,
                  nos baseamos exclusivamente em nosso interesse legítimo. Para
                  saber mais sobre em quais casos, especificamente, nos valemos
                  desta base legal, ou para obter mais informações sobre os
                  testes que fazemos para termos certeza de que podemos
                  utilizá-la, entre em contato com nosso Encarregado de Proteção
                  de Dados Pessoais por algum dos canais informados nesta
                  Política de Privacidade, na seção "Como entrar em contato
                  conosco".
                </p>
              </div>
              <div>
                <p className="font-bold">5. Direitos do usuário</p>
              </div>
              <div>
                <p>
                  O usuário do site possui os seguintes direitos, conferidos
                  pela Lei de Proteção de Dados Pessoais:
                </p>
              </div>
              <div>
                <ul>
                  <li>- confirmação da existência de tratamento;</li>
                  <li>- acesso aos dados;</li>
                  <li>
                    - correção de dados incompletos, inexatos ou desatualizados;
                  </li>
                  <li>
                    - anonimização, bloqueio ou eliminação de dados
                    desnecessários, excessivos ou tratados em desconformidade
                    com o disposto na lei;
                  </li>
                  <li>
                    - portabilidade dos dados a outro fornecedor de serviço ou
                    produto, mediante requisição expressa, de acordo com a
                    regulamentação da autoridade nacional, observados os
                    segredos comercial e industrial;
                  </li>
                  <li>
                    - eliminação dos dados pessoais tratados com o consentimento
                    do titular, exceto nos casos previstos em lei; &nbsp;
                  </li>
                  <li>
                    - informação das entidades públicas e privadas com as quais
                    o controlador realizou uso compartilhado de dados; &nbsp;
                  </li>
                  <li>
                    - informação sobre a possibilidade de não fornecer
                    consentimento e sobre as consequências da negativa; &nbsp;
                  </li>
                  <li>- revogação do consentimento.</li>
                </ul>
              </div>
              <div>
                <p>
                  É importante destacar que, nos termos da LGPD, não existe um
                  direito de eliminação de dados tratados com fundamento em
                  bases legais distintas do consentimento, a menos que os dados
                  sejam desnecessários, excessivos ou tratados em
                  desconformidade com o previsto na lei.
                </p>
              </div>
              <div>
                <p>1. Como o titular pode exercer seus direitos</p>
              </div>
              <div>
                <p>
                  Os titulares de dados pessoais tratados por nós poderão
                  exercer seus direitos por meio de pedido de acesso aos dados
                  através do e-mail hmqualidade@hmcoutinho.com.br
                  Alternativamente, se desejar, o titular poderá enviar um
                  e-mail ou uma correspondência ao nosso Encarregado de Proteção
                  de Dados Pessoais. As informações necessárias para isso estão
                  na seção "Como entrar em contato conosco" desta Política de
                  Privacidade.
                </p>
              </div>
              <div>
                <p>
                  Os titulares de dados pessoais tratados por nós poderão
                  exercer seus direitos a partir do envio de mensagem ao nosso
                  Encarregado de Proteção de Dados Pessoais, seja por e-mail ou
                  por correspondência. As informações necessárias para isso
                  estão na seção "Como entrar em contato conosco" desta Política
                  de Privacidade.
                </p>
              </div>
              <div>
                <p>
                  Para garantir que o usuário que pretende exercer seus direitos
                  é, de fato, o titular dos dados pessoais objeto da requisição,
                  poderemos solicitar documentos ou outras informações que
                  possam auxiliar em sua correta identificação, a fim de
                  resguardar nossos direitos e os direitos de terceiros. Isto
                  somente será feito, porém, se for absolutamente necessário, e
                  o requerente receberá todas as informações relacionadas.
                </p>
              </div>
              <div>
                <p className="font-bold">
                  6. Medidas de segurança no tratamento de dados pessoais
                </p>
              </div>
              <div>
                <p>
                  Empregamos medidas técnicas e organizativas aptas a proteger
                  os dados pessoais de acessos não autorizados e de situações de
                  destruição, perda, extravio ou alteração desses dados.
                </p>
              </div>
              <div>
                <p>
                  As medidas que utilizamos levam em consideração a natureza dos
                  dados, o contexto e a finalidade do tratamento, os riscos que
                  uma eventual violação geraria para os direitos e liberdades do
                  usuário, e os padrões atualmente empregados no mercado por
                  empresas semelhantes à nossa.
                </p>
              </div>
              <div>
                <p>
                  Entre as medidas de segurança adotadas por nós, destacamos as
                  seguintes:
                </p>
              </div>
              <div>
                <ul>
                  <li>
                    - Armazenamento de senhas utilizando hashes criptográficos;
                  </li>
                  <li>- restrições de acessos a bancos de dados; </li>
                  <li>- monitoramento de acesso físicos a servidores;</li>
                  <li>
                    - Segurança de ataques de acordo com as políticas da Amazon
                    Aws.
                  </li>
                </ul>
              </div>
              <div>
                <p>
                  Ainda que adote tudo o que está ao seu alcance para evitar
                  incidentes de segurança, é possível que ocorra algum problema
                  motivado exclusivamente por um terceiro - como em caso de
                  ataques de hackers ou crackers ou, ainda, em caso de culpa
                  exclusiva do usuário, que ocorre, por exemplo, quando ele
                  mesmo transfere seus dados a terceiro. Assim, embora sejamos,
                  em geral, responsáveis pelos dados pessoais que tratamos, nos
                  eximimos de responsabilidade caso ocorra uma situação
                  excepcional como essas, sobre as quais não temos nenhum tipo
                  de controle.
                </p>
              </div>
              <div>
                <p>
                  De qualquer forma, caso ocorra qualquer tipo de incidente de
                  segurança que possa gerar risco ou dano relevante para
                  qualquer de nossos usuários, comunicaremos os afetados e a
                  Autoridade Nacional de Proteção de Dados acerca do ocorrido,
                  em conformidade com o disposto na Lei Geral de Proteção de
                  Dados.
                </p>
              </div>
              <div>
                <p className="font-bold">
                  7. Reclamação a uma autoridade de controle
                </p>
              </div>
              <div>
                <p>
                  Sem prejuízo de qualquer outra via de recurso administrativo
                  ou judicial, os titulares de dados pessoais que se sentirem,
                  de qualquer forma, lesados, podem apresentar reclamação à
                  Autoridade Nacional de Proteção de Dados.
                </p>
              </div>
              <div>
                <p className="font-bold">8. Alterações nesta política</p>
              </div>
              <div>
                <p>
                  A presente versão desta Política de Privacidade foi atualizada
                  pela última vez em: 22/10/2020.
                </p>
              </div>
              <div>
                <p>
                  Nos reservamos o direito de modificar, a qualquer momento, as
                  presentes normas, especialmente para adaptá-las às eventuais
                  alterações feitas em nosso site, seja pela disponibilização de
                  novas funcionalidades, seja pela supressão ou modificação
                  daquelas já existentes.
                </p>
              </div>
              <div>
                <p>
                  Sempre que houver uma modificação, nossos usuários serão
                  notificados acerca da mudança.
                </p>
              </div>
              <div>
                <p className="font-bold">9. Como entrar em contato conosco</p>
              </div>
              <div>
                <p>
                  Para esclarecer quaisquer dúvidas sobre esta Política de
                  Privacidade ou sobre os dados pessoais que tratamos, entre em
                  contato com nosso Encarregado de Proteção de Dados Pessoais,
                  por algum dos canais mencionados abaixo:
                </p>
              </div>
              <div>
                <ul>
                  <li>- E-mail: protecaodedados@volendam.com.br</li>
                  <li>- Telefone: (14) 3010-6150</li>
                  <li>
                    - Endereço postal: Av. Júlia Kubitschek 16 - Cobertura 01 -
                    Centro 28905-000 Cabo Frio, RJ.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}
